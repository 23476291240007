import React from "react";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

function CommonPageNotFound(props) {
  const navigate = useNavigate();

  return (
    <div>
      <h3>404</h3>
      <div>Sorry, page not found~</div>
      <Divider sx={{ my: 2 }} />
      <div onClick={() => navigate(-1)}>
        click here back to <strong>InfraSys</strong>
      </div>
    </div>
  );
}

export default CommonPageNotFound;

import React from 'react';
import {Box, Card, CardContent, Grid, Skeleton} from "@mui/material";
import Divider from "@mui/material/Divider";

function PagePartialCard({ xs = 12, title, subtitle, isLoading, renderActions, icon,  ...props }) {

    const renderSkeleton = () => {
      return <Box>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
      </Box>
    }

    return (
        <Grid item xs={xs} {...props}>
            <Card>
                <CardContent>
                    <Box sx={{ mb: 2 }}>
                        <Box>
                            {icon && <span style={{ marginRight: 16 }}>{icon}</span>}
                            <span style={{ fontSize: 20, fontWeight: 500, marginRight: 16 }}>
                            {title}
                        </span>
                            <span style={{ fontSize: 12, color: "rgba(0,0,0,0.6)" }}>{subtitle}</span>
                            <span style={{float: "right"}}>
                            {renderActions && renderActions()}
                        </span>
                        </Box>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    {isLoading ? renderSkeleton() : props.children}
                </CardContent>
            </Card>
        </Grid>
    );
}

export default PagePartialCard;
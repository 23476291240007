import React from "react";
import { useMutation, useQuery } from "react-query";
import {
  getEntrypointByUid,
  listEntrypoints,
  createEntrypoint,
  deleteEntrypointByUid,
  patchEntrypointByUid,
  saveEntrypointByUid,
} from "../../services/entrypoint";

export const useCreateEntrypoint = () => {
  return useMutation(createEntrypoint);
};

export const useListEntrypoints = (listOptions = {}) => {
  return useQuery(["entrypoints"], () => listEntrypoints(listOptions), {
    placeholderData: {
      items: [],
      total: 0,
    },
  });
};

export const useGetEntrypointByUid = (uid) => {
  return useQuery(
    ["entrypoints", uid],
    () => getEntrypointByUid(uid),
    {
      placeholderData: {
        id: ""
      },
    }
  );
};

export const usePatchEntrypointByUid = () => {
  return useMutation(patchEntrypointByUid);
};

export const useSaveEntrypointByUid = () => {
  return useMutation(saveEntrypointByUid);
};

export const useDeleteEntrypointByUid = () => {
  return useMutation(deleteEntrypointByUid);
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import {Box, Button, Paper, Stack} from "@mui/material";
import {
  useDeleteEntrypointByUid,
  useListEntrypoints,
} from "../../../../hooks/query/entrypoint";
import CountdownDialog from "../../../../components/Dialog/Countdown";
import { SpecTypeChip } from "./Chip";
import CustomTable from "../../../../components/Table/CustomTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TuneIcon from "@mui/icons-material/Tune";
import EntrypointEditDialog from "../Dialog/EntrypointEditDialog";
import {entrypointSpecType} from "../../../../constants/entrypoint";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Avatar from "@mui/material/Avatar";

function EntrypointTable(props) {
  let navigate = useNavigate();
  const { data: entrypoints, isFetching, refetch: refetchEntrypoints } = useListEntrypoints();

  const { mutate: deleteEntrypointByUid } = useDeleteEntrypointByUid();
  const [dialogConfirmDeleteOpen, setDialogConfirmDeleteOpen] = useState(false);
  const [deleteEntrypointId, setDeleteEntrypointId] = useState("");
  const [entrypointCreateDialogOpen, setEntrypointCreateDialogOpen] =  useState(false);
  const [editingEntrypoint, setEditingEntrypoint] = useState({})

  const handleClickDelete = () => {
    deleteEntrypointByUid(
      { uid: deleteEntrypointId },
      { onSuccess: () => refetchEntrypoints() }
    );
    setDialogConfirmDeleteOpen(false);
    setDeleteEntrypointId("");
  };


    const renderAvatar = (entrypoint) =>{
        if (entrypoint.spec.logo_src && entrypoint.spec.logo_src !== "") {
            return <img style={{ width: 36, height: 36,  margin: '0 auto', lineHeight: '40px'}}  alt="" src={entrypoint.spec.logo_src}/>
        }else {
            switch (entrypoint.spec.type) {
                case entrypointSpecType.Documents:
                    return <MenuBookIcon sx={{fontSize: "32px",}}/>;
                default:
                    return <Avatar
                        alt="Remy Sharp"
                        src="img/systems/default.png"
                        sx={{ width: 36, height: 36,  margin: '0 auto', lineHeight: '40px'}}
                    />
            }
        }
    }

  const columns = [
      {
          field: "icon",
          headerName: "",
          width: 60,
          renderCell: (data) => {
              return (
                  <span>{renderAvatar(data.row)}</span>
              );
          },
      },
    {
      field: "name",
      headerName: "名称/UID",
        width: 400,
      valueGetter: (data) =>
        `${data.row.metadata.name} [uid: ${data.row.metadata.uid}]`,
      renderCell: (data) => {
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
              <Box>
                  <div style={{ fontWeight: "bold" }}>
                      <a href={data.row.spec.site_urls.main} target="_blank" style={{textDecoration: 'none', color: '#000'}}>{data.row.metadata.name}</a>
                  </div>
                  <div style={{ fontSize: 12, color: "rgba(0,0,0,0.6)" }}>
                      {data.row.metadata.uid}
                  </div>
              </Box>
          </Stack>
        );
      },
    },
    {
      field: "description",
      headerName: "描述",
      flex: 1,
      valueGetter: (params) => params.row.metadata.description,
    },
    {
      field: "type",
      headerName: "类型",
        width: 120,
        valueGetter: (params) => params.row.spec?.type,
      renderCell: (data) => SpecTypeChip(data.row.spec?.type),
    },
    {
      field: "actions",
      headerName: "操作",
      width: 220,
      renderCell: (data) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Button
              startIcon={<TuneIcon />}
              variant={"outlined"}
              onClick={() => {
                  setEntrypointCreateDialogOpen(true)
                  setEditingEntrypoint(data.row)
              }}
            >
              编辑
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant={"outlined"}
              color={"error"}
              onClick={() => {
                setDeleteEntrypointId(data.row.metadata.uid);
                setDialogConfirmDeleteOpen(true);
              }}
            >
              移除
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%" }}>
      <CountdownDialog
        open={dialogConfirmDeleteOpen}
        title="确认删除"
        description="是否确认删除快捷导航？"
        handleClose={() => setDialogConfirmDeleteOpen(false)}
        handleConfirm={handleClickDelete}
      />
        <EntrypointEditDialog
            open={entrypointCreateDialogOpen}
            setOpen={setEntrypointCreateDialogOpen}
            entrypoint={editingEntrypoint}
        />
      <CustomTable
        rows={entrypoints.items}
        columns={columns}
        getRowId={(row) => row.metadata.uid}
        loading={isFetching}
      />
    </Paper>
  );
}

export default EntrypointTable;

import {
  get,
  patch,
  put,
} from "../utils/request";

const getByName =  ({name}) => {
  return  get(`/proxy/@sys/api/v1/users/${name}`);
};

const listAll =  ({listOptions}) => {
  return  get(`/proxy/@sys/api/v1/users`, listOptions);
};

const patchByName = ({ name, data }) => {
  return patch(`/proxy/@sys/api/v1/users/${name}`, data);
};

const saveByName = ({ name, data }) => {
  return put(`/proxy/@sys/api/v1/users/${name}`, data);
};

const UserService = {
  getByName,
  listAll,
  patchByName,
  saveByName
}

export default UserService

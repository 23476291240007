import React, {useState} from 'react';
import {Button, Card, Grid, IconButton, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {entrypointSpecType} from "../../constants/entrypoint";
import MapIcon from '@mui/icons-material/Map';
import {useNavigate} from "react-router-dom";

function ServiceHoverCard({entrypointCard, entrypointUid, name, description, imgSrc, isCollected, onClickStar, ...props}) {

    const [raised, setRaised] = useState(false)
    const navigate = useNavigate()

    const renderCardAvatar = (entrypointCard) =>{
        if (entrypointCard.spec.logo_src && entrypointCard.spec.logo_src !== "") {
            // return <img style={{ width: 36, height: 36, padding: '24px 8px 8px 8px', margin: '0 auto', lineHeight: '70px'}}  alt={name} src={entrypointCard.spec.logo_src}/>
            return <Avatar
                variant="square"
                alt={name}
                src={entrypointCard.spec.logo_src}
                sx={{ width: 32, height: 32, margin: '12px auto', lineHeight: '70px'}}
            />
        }else {
            switch (entrypointCard.spec.type) {
                case entrypointSpecType.Documents:
                    return <MapIcon sx={{fontSize: "42px", paddingTop: "16px"}}/>;
                default:
                    return <Avatar
                        variant="square"
                        alt="Remy Sharp"
                        src="img/systems/default.png"
                        sx={{ width: 32, height: 32,  margin: '12px auto', lineHeight: '70px'}}
                    />
            }
        }
    }

    return (
        <Card
            onMouseOver={()=>setRaised(true)}
            onMouseOut={()=>setRaised(false)}
            raised={raised}
            sx={{padding: '8px 8px 24px 8px', border: '1px solid #eee', height: '80px'}}
            {...props}
        >

            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Box sx={{width: '56px', textAlign: 'center'}}>
                        {renderCardAvatar(entrypointCard)}
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Stack spacing={0} sx={{padding: '0 0 0 8px'}} >
                        <Stack direction="row" sx={{fontSize: '14px', fontWeight: 'bold'}} alignItems="center" justifyContent="space-between">
                            {
                                entrypointCard.spec.site_urls.main.startsWith("/")
                                    ?
                                    <span style={{textDecoration: 'none', color: '#000'}} onClick={()=>navigate(entrypointCard.spec.site_urls.main)}>{name}</span>
                                    :
                                    <a href={entrypointCard.spec.site_urls.main} target="_blank" style={{textDecoration: 'none', color: '#000'}}>{name}</a>
                            }
                            <span style={{float: 'right'}}>
                                {entrypointCard.spec.docs_site_url !== "" && <IconButton size="small"><a href={entrypointCard.spec.docs_site_url} target="_blank" style={{textDecoration: 'none', color: 'rgba(0,0,0,0.7)'}}><MapIcon fontSize="14px"/></a></IconButton>}
                                <IconButton size="small" onClick={()=>onClickStar(entrypointUid, isCollected)}>
                                    {isCollected ? <StarIcon fontSize="14px" sx={{color: '#E60012'}} /> : <StarBorderIcon fontSize="14px"/>}
                                </IconButton>
                            </span>
                        </Stack>
                        <Box sx={{fontSize: '12px', width: "240px",color: 'rgba(0,0,0,0.6)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{description}</Box>
                    </Stack>
                </Grid>
            </Grid>

        </Card>
    );
}

export default ServiceHoverCard;
import React from 'react';
import {Outlet} from "react-router-dom";
import {Box} from "@mui/material";

function Tools(props) {
    return (
        <Box sx={{padding: "16px"}}>
            {/* 告警屏蔽工具页面路由出口 */}
            <Outlet />
        </Box>
    );
}

export default Tools;
import React from "react";
import {GlobalConfig} from "../globalConfig"
import {Navigate, useRoutes} from "react-router-dom";

import PageNotFound from "../pages/Common/PageNotFound";
import NoPrivilege from "../pages/Common/NoPrivilege";
import Home from "../pages/Home/Home";
import Admin from "../pages/Admin/Admin";

import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ViewCompactAltIcon from "@mui/icons-material/ViewCompactAlt";
import ToolAlarmMasking from "../pages/Tools/ToolAlarmMasking/ToolAlarmMasking";
import LayoutContainer from "../components/Layout/LayoutContainer";
import Tools from "../pages/Tools/Tools";


export const NavRoutes = [
  {
    path: "/home",
    title: "首页",
    isNav: true,
    icon: <ViewCompactAltIcon />,
    element: <Home />,
  },
  {
    path: "/admin",
    title: "管理面板",
    isNav: true,
    icon: <SettingsApplicationsIcon />,
    element: <Admin />,
  },
  {
    path: "/tools",
    title: "常用工具",
    element: <Navigate to="/tools/alarm-masking"/>
  },
  {
    // 工具路由
    path: "/tools",
    element: <Tools/>,
    title: "常用工具",
    children: [
      {
        // 告警屏蔽
        path: "/tools/alarm-masking",
        element: <ToolAlarmMasking/>,
        title: "告警屏蔽",
      },
    ],
  },
]

export const RouteConfig = [
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/",
    element: <Navigate to={GlobalConfig.router.entryRoute}/>
  },
  {
    path: "/",
    element: <LayoutContainer />,
    children: NavRoutes,
  },
  {
    path: "/403",
    element: <NoPrivilege />,
  },
];


function AppRoutes() {
  return useRoutes(RouteConfig)
}

export default AppRoutes;
import React, { useState } from 'react'
import './Navbar.css'

import NearMeIcon from '@mui/icons-material/NearMe';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HandymanIcon from '@mui/icons-material/Handyman';
import StarIcon from '@mui/icons-material/Star';

import {Box, Button, Grid, LinearProgress, Stack} from "@mui/material";
import {entrypointSpecType} from "../../../constants/entrypoint";

const Navbar = ({entrypoints, ...props}) => {

    // change nav color when scrolling
    const [scrolled, setScrolled] = useState(false)
    const changeColor = () => {
        // if (window.scrollY >= window.innerHeight - 40) {
        if (window.scrollY >= 50) {
            setScrolled(true)
        }else {
            setScrolled(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    const [click, setClick] = useState(false)
    const closeMenu = () => setClick(false)

    return (
        <div className={scrolled?'header header-bg' : 'header'}>
            <nav>
                <div className="wrapper">
                    <ul className={scrolled?'nav-links' : 'nav-links before-scroll'}>
                        <li>
                            <a href="src/pages/Home/Navbar/Navbar#" className="desktop-item"><Button startIcon={<NearMeIcon />} sx={{mt: "16px", color: "#fff"}} variant="text">快捷导航</Button></a>
                            <div className="mega-box">
                                <div className="content">
                                    <div className="row" style={{textAlign: 'center'}}>
                                        <img className="logo" src="img/logo/logo.png" alt="InfraSys" style={{height: '180px', margin: '0 auto'}} />
                                    </div>
                                    <div className="row">
                                        <header><DashboardIcon sx={{fontSize: '18px', marginRight: '8px'}} />内部系统导航</header>
                                        <ul className="mega-links">
                                            {entrypoints.items.filter(epCard => epCard.spec?.type === entrypointSpecType.InternalSystem).map((epCard, index) => (<Grid key={index} item xs={12} md={6} lg={4}>
                                                <li>
                                                    <a href={epCard.spec.site_urls.main} target="_blank" style={{padding: '8px 16px'}}>
                                                        <Stack spacing={1}>
                                                            <div style={{fontSize: '14px', fontWeight: 'bold', lineHeight: '18px'}}>{epCard.metadata.name}</div>
                                                            <div style={{fontSize: '12px', lineHeight: '16px', color: 'rgba(255,255,255,0.5)'}}>{epCard.metadata.description}</div>
                                                        </Stack>
                                                     </a>
                                                </li>
                                            </Grid>))}
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header><HandymanIcon sx={{fontSize: '18px', marginRight: '8px'}} />快捷功能入口</header>
                                        <ul className="mega-links">
                                            {entrypoints.items.filter(epCard => epCard.spec?.type === entrypointSpecType.QuickFunctions).map((epCard, index) => (<Grid key={index} item xs={12} md={6} lg={4}>
                                                <li>
                                                    <a href={epCard.spec.site_urls.main} target="_blank" style={{padding: '8px 16px'}}>
                                                        <Stack spacing={1}>
                                                            <div style={{fontSize: '14px', fontWeight: 'bold', lineHeight: '18px'}}>{epCard.metadata.name}</div>
                                                            <div style={{fontSize: '12px', lineHeight: '16px', color: 'rgba(255,255,255,0.5)'}}>{epCard.metadata.description}</div>
                                                        </Stack>
                                                    </a>
                                                </li>
                                            </Grid>))}
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header><StarIcon sx={{fontSize: '18px', marginRight: '8px'}} />常用运维系统</header>
                                        <ul className="mega-links">
                                            {entrypoints.items.filter(epCard => epCard.spec?.type === entrypointSpecType.ExternalSystem).map((epCard, index) => (<Grid key={index} item xs={12} md={6} lg={4}>
                                                <li>
                                                    <a href={epCard.spec.site_urls.main} target="_blank" style={{padding: '8px 16px'}}>
                                                        <Stack spacing={1}>
                                                            <div style={{fontSize: '14px', fontWeight: 'bold', lineHeight: '18px'}}>{epCard.metadata.name}</div>
                                                            <div style={{fontSize: '12px', lineHeight: '16px', color: 'rgba(255,255,255,0.5)'}}>{epCard.metadata.description}</div>
                                                        </Stack>
                                                    </a>
                                                </li>
                                            </Grid>))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Navbar

import React from 'react';
import {Box, Grid} from "@mui/material";

function PageContainer({title, subtitle, renderActions, ...props}) {

    return (
        <div>
            <Box sx={{ textAlign: "left", margin: "16px 0 32px 0" }}>
                {/* 页标题 */}
                <span style={{fontSize: "24px", fontWeight: "500"}}>
                    {title || "[标题]"}
                </span>
                {/* 页描述 */}
                <span
                    style={{
                        marginLeft: 16,
                        fontSize: 14,
                        fontWeight: "normal",
                        color: "rgba(0,0,0,0.6)",
                    }}
                >
                  {subtitle}
                </span>
                <span style={{float: "right"}}>
                    {renderActions && renderActions()}
                </span>
            </Box>
            <Grid container spacing={2}>
                { props.children }
            </Grid>
        </div>
    );
}

export default PageContainer;
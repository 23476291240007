import React, {useEffect, useState} from 'react';
import {Alert, TabContext, TabList, TabPanel} from "@mui/lab";
import {Grid, Tab} from "@mui/material";
import Box from "@mui/material/Box";
import EntrypointAdmin from "./Entrypoint/EntrypointAdmin";
import {userSysRoleBinding} from "../../constants/user";
import {useDispatch, useSelector} from "react-redux";
import {getUserByName} from "../../state/modules/user";
import {getAuthUserName} from "../../utils/cookie";

function Admin(props) {
    const [activeTab, setActiveTab] = useState("entrypoints");

    const dispatch = useDispatch()
    const userState = useSelector(state => state.user)

    return (
        <TabContext value={activeTab}>
            <Box sx={{p: 2, minHeight: '800px'}}>
                <h3 style={{marginBottom: '32px'}}>管理面板</h3>
                <TabList
                    onChange={(e, tabValue) => {
                        setActiveTab(tabValue);
                    }}
                    aria-label="lab API tabs example"
                    sx={{ background: "#fff", borderBottom: "1px solid #ddd" }}
                >
                    <Tab label="导航管理" value="entrypoints" />
                </TabList>

                {(userState.current?.role_binding?.sys_role === userSysRoleBinding.Admin || userState.current?.role_binding?.sys_role === userSysRoleBinding.Maintainer) ? <Box>
                    <TabPanel value="entrypoints" sx={{ p: 0 }}>
                        <EntrypointAdmin />
                    </TabPanel>
                    <TabPanel value="users" sx={{ p: 0 }}>

                    </TabPanel>
                </Box> : <Box sx={{margin: "8px"}}><Alert severity="warning">无管理面板权限，如有需要请联系 Sys 门户管理员 @jiangyingchi</Alert></Box>}

            </Box>

        </TabContext>
    );
}

export default Admin;
/**
 * User 状态管理模块
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../../services/user";
import {handleResponseEnvelope} from "../../utils/request";
import {getAuthUserName} from "../../utils/cookie";

export const getUserByName = createAsyncThunk(
    "users/getByName",
    async ({ name }, {rejectWithValue}) => {
        try {
            return handleResponseEnvelope((await UserService.getByName({name})).data);
        }catch (e) {
            return rejectWithValue(e.toString())
        }
    }
);

export const listUsers = createAsyncThunk(
    "users/listAll",
    async ({listOptions}, {rejectWithValue}) => {
        try {
            return handleResponseEnvelope((await UserService.listAll({listOptions})).data);
        }catch (e) {
            return rejectWithValue(e.toString())
        }
    }
);

export const saveUserByName = createAsyncThunk(
    "users/saveByName",
    async ({ name, data }, {rejectWithValue}) => {
        try {
            return handleResponseEnvelope((await UserService.saveByName({name, data})).data);
        }catch (e) {
            return rejectWithValue(e.toString())
        }
    }
);

export const patchUserByName = createAsyncThunk(
    "users/patchByName",
    async ({ name, data }, {rejectWithValue}) => {
        try {
            return handleResponseEnvelope((await UserService.patchByName({name, data})).data);
        }catch (e) {
            return rejectWithValue(e.toString())
        }
    }
);


export const userSlice = createSlice({
    name: "user",
    initialState: {
        current: {
            metadata: {
                name: getAuthUserName(),
                fullname: "",
                team: {
                    code: "",
                    name: "",
                },
                annotations: {},
            },
            collection: {
                entrypoints: []
            },
            role_binding: {
                project_role: {
                    _: "_readonly",
                },
                sys_role: "_readonly"
            },
        },
        list: {items: [], total: 0},
        error: "",
        loading: false,
    },
    extraReducers: {
        [getUserByName.fulfilled]: (state, action) => {
            state.current = action.payload
        },
        [listUsers.fulfilled]: (state, action) => {
            state.users = action.payload
        },
        [saveUserByName.fulfilled]: (state, action) => {

        },
        [patchUserByName.fulfilled]: (state, action) => {

        },
    },
});

export default userSlice.reducer;
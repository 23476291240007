import { configureStore } from '@reduxjs/toolkit';
import siteSlice from './modules/site';
import userSlice from './modules/user';

export const store = configureStore({
  reducer: {
    site: siteSlice,
    user: userSlice,
  },
});

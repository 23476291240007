import React from 'react';
import {Nav} from "@douyinfe/semi-ui";
import {GlobalConfig} from "../../globalConfig";
import {Button, Stack} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import {getAuthUserName} from "../../utils/cookie";
import {useNavigate} from "react-router-dom";
import LogoWhite from '../../assets/logo-w.png'

function LayoutHeader(props) {

    const navigate = useNavigate()

    return (
        <Nav
            mode={'horizontal'}
            onSelect={key => console.log(key)}
            style={{...GlobalConfig.style.layout.header}}
            header={<div onClick={()=>navigate("/")}>
                <Stack alignItems="center">
                    <img src={LogoWhite} alt="" style={{ width: "100px", opacity: "0.7"}} />
                    <Box sx={{fontSize: "18px"}}>系统保障组</Box>
                </Stack>
            </div>}
            footer={
                <>
                    <Stack spacing={2} direction="row">
                        <Stack sx={{fontSize: "14px"}} spacing={1} direction="row" justifyContent="center" alignItems="center">
                            <AccountCircleIcon sx={{fontSize: "18px"}} />
                            <Box>{getAuthUserName() || "Guest"}</Box>
                        </Stack>
                    </Stack>
                </>
            }
        />
    );
}

export default LayoutHeader;
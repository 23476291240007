import React from 'react';
import {Button, Skeleton, Stack} from "@mui/material";
import Box from "@mui/material/Box";

function LoadingSkeleton(props) {
    return (<Stack direction="row" spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
            <Skeleton animation="wave" variant="circular" width="36px" height="36px"/>
            <Stack>
                <Skeleton animation="wave" width="320px" height="30px"/>
                <Skeleton animation="wave" width="320px" height="20px"/>
            </Stack>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
            <Skeleton animation="wave" variant="circular" width="36px" height="36px"/>
            <Stack>
                <Skeleton animation="wave" width="320px" height="30px"/>
                <Skeleton animation="wave" width="320px" height="20px"/>
            </Stack>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
            <Skeleton animation="wave" variant="circular" width="36px" height="36px"/>
            <Stack>
                <Skeleton animation="wave" width="320px" height="30px"/>
                <Skeleton animation="wave" width="320px" height="20px"/>
            </Stack>
        </Stack>
    </Stack>);
}

export default LoadingSkeleton;
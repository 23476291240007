import React, {useState} from 'react';
import EntrypointTable from "./Table/Entrypoint";
import {Button, Grid} from "@mui/material";
import TopActionsArea from "../../../components/TopBar/TopActionsArea";
import ContentArea from "../../../components/ContentArea/ContentArea";
import EntrypointCreateDialog from "./Dialog/EntrypointCreateDialog";

function EntrypointAdmin(props) {
    const [entrypointCreateDialogOpen, setEntrypointCreateDialogOpen] =  useState(false);

    return (
        <div>
            <ContentArea>
                <TopActionsArea>
                    <Button onClick={() => setEntrypointCreateDialogOpen(true)}>
                        新增快捷导航
                    </Button>
                </TopActionsArea>
                <Grid container spacing={2}>
                    <Grid sx={{ mb: 3 }} item xs={12}>
                        <EntrypointTable />
                    </Grid>
                </Grid>
            </ContentArea>


            <EntrypointCreateDialog open={entrypointCreateDialogOpen} setOpen={setEntrypointCreateDialogOpen} />

        </div>
    );
}

export default EntrypointAdmin;
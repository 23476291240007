import React from 'react';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {GlobalConfig} from "../../globalConfig";
import Box from "@mui/material/Box";

function Copyright() {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{
                pl: 2,
                pb: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
            }}
        >
            <Link
                color="inherit"
                href="/"
                style={{ marginRight: 6, textDecoration: "none" }}
            >
                <strong>{GlobalConfig.site.title}</strong>
            </Link>
            <span style={{ marginRight: 6 }}>© {new Date().getFullYear()}</span>
            <span style={{ marginRight: 6 }}>
        Infra.Sys | 系统保障组
      </span>
        </Typography>
    );
}

function LayoutFooter(props) {
    return (
        <Box component="footer">
            <Copyright />
        </Box>
    );
}

export default LayoutFooter;

import {post} from "../utils/request";


const maskAlarm =  (payload = {}) => {
  // 直接请求会出现跨域问题，通过 /proxy/@blackhole 配置代理，详情见 Tools 目录下 README.md
  return post(`/proxy/@blackhole/alarmv2/shield`, payload);
};

const BlackHoleService = {
  maskAlarm
}

export default BlackHoleService

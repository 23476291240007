import React, {useEffect, useState} from 'react';
import {GlobalConfig} from "../../globalConfig";
import {Nav} from "@douyinfe/semi-ui";
import {useNavigate} from "react-router-dom";
import {NavRoutes} from "../../routes";

function LayoutSiderNav(props) {

    const navigate = useNavigate()

    const [navItems, setNavItems] = useState([])

    const convertRoutesToNavItems = () => {
        return NavRoutes.filter(navRoute => navRoute.isNav).map((navRoute) => {
            return {
                itemKey: navRoute.path,
                text: navRoute.title,
                icon: navRoute.icon,
                items: navRoute.children?.filter(childRoute => childRoute.isNav).map(childRoute => {
                    return {
                        itemKey: childRoute.path,
                        text: childRoute.title,
                        icon: childRoute.icon,
                    }
                }) || []
            }
        })
    }

    useEffect(()=>{
        setNavItems(convertRoutesToNavItems())
    }, [])


    return (
        <Nav
            style={{...GlobalConfig.style.layout.nav}}
            bodyStyle={{ height: "calc(100vh - 137px)", padding: "24px 0px"}}
            items={navItems}
            defaultIsCollapsed={true} // 默认收起侧边栏
            onSelect={item => {
                if (item.itemKey.startsWith("http")) {
                    window.open(item.itemKey, "_blank")
                } else {
                    navigate(item.itemKey);
                }
            }}
            footer={{
                collapseButton: true,
            }}
        />
    );
}

export default LayoutSiderNav;
import "./App.css";
import "./theme.less";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useNotification } from "./hooks/useNotification";
import {getAuthUserName, getAuthV2Token} from "./utils/cookie";
import AppRoutes from "./routes";
import {Slide} from "@mui/material";
import {SnackbarProvider} from "notistack";
import {useDispatch} from "react-redux";
import {getUserByName} from "./state/modules/user";

export const AppContext = React.createContext();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      onError: (err) => {
        console.log("query error:", err.toString());
      },
    },
    mutations: {
      retry: false,
      onError: (err) => {
        console.log("query error:", err.toString());
      },
    },
  },
});

function App(props) {

  const dispatch = useDispatch()

  useEffect(() => {
    checkAuthLogin()
    if (getAuthUserName() !== "") {
      dispatch(getUserByName({name: getAuthUserName()}))
    }
  }, []);


  const checkAuthLogin = () => {
    let access_token = getAuthV2Token();
    if (!access_token || access_token === "") {
      console.log("未登录，跳转至 Auth 登录页面");
      window.location.href = "/login";
    }
  }

  return (
      <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          TransitionComponent={Slide}
          transitionDuration={{ enter: 100, exit: 200 }}
      >
        <QueryClientProvider client={queryClient}>
          <AppContext.Provider value={{}}>
            <div className="App">
              <Router>
                <AppRoutes />
              </Router>
            </div>
          </AppContext.Provider>
        </QueryClientProvider>
      </SnackbarProvider>
  );
}

export default App;

import React, {useState} from 'react';
import {Form, Button, Row, Col, Space, Popconfirm, Notification} from '@douyinfe/semi-ui';
import PageContainer from "../../../components/Page/PageContainer";
import PagePartialCard from "../../../components/Page/PagePartialCard";
import {useNavigate} from "react-router-dom";
import BlackHoleService from "../../../services/blackhole";

function ToolAlarmMasking(props) {

    const navigate = useNavigate()
    const [formApi, setFormApi] = useState(null)

    // 表单确认提交处理
    const handleConfirm = () => {
        // 表单校验
        formApi.validate()
            .then((values) => {
                // 校验通过
                console.log("OK:", values);

                // 构造 payload
                let payload = {
                    project: values.project,
                    share_project: values.share_project,
                    entity_type: "host",
                    mid: ["*"], // 全部规则
                    type: 1,
                    rules: {
                        a: {key: "gid", op: "eq", value: values.gid}
                    },
                    ttl: values.ttl,
                    desc: values.desc,
                }

                if (values.service_code) {
                    payload.rules.b = {key: "service_code", op: "eq", value: values.service_code}
                    payload.express = "a&b"
                }else {
                    payload.express = "a"
                }

                console.log("payload: ", payload)

                // 执行请求
                BlackHoleService.maskAlarm(payload).then(res => {
                    console.log("result data: ", res.data)
                    Notification.success({title: "屏蔽成功", content: <div>集群: {values.gid} {values.service_code && <span> 服务: {values.service_code} </span>} 屏蔽时长: {values.ttl} 秒</div>})
                    window.location.reload()
                }).catch(err => {
                    Notification.error({title: "请求失败", content: <div>{err.toString()}</div>})
                })
            })
            .catch((errors) => {
                // 校验不通过
                console.log("FAILED:", errors);
            });
    }

    return (
        // 页面主容器
        <PageContainer title="快捷工具 - 告警屏蔽" subtitle="屏蔽集群告警指定时间">
            {/* 工具区域，可以有多个 PagePartialCard，根据需要布局 */}
            <PagePartialCard title="host 告警屏蔽" subtitle="集群 & 服务 host 告警屏蔽" xs={12}>
                {/* 工具相关表单区域 */}
                <Form getFormApi={(formApi)=>setFormApi(formApi)}>
                    <Row>
                        {/* 布局分栏，单行 span 最大 24，offset 为从左往右偏移量，例：如果两个 span={12} 的 Col，则表示一行存在等宽的两栏 */}
                        <Col span={8} offset={3}>
                            {/* 表单字段 */}
                            <Form.Input
                                field='project'
                                label="项目"
                                initValue="cld"
                                trigger='blur'
                                rules={[
                                    { required: true, message: "该字段为必填项" },
                                ]}
                            />
                        </Col>

                        <Col span={8} offset={1}>
                            <Form.Input
                                field='share_project'
                                label="分享项目"
                                initValue="-"
                                trigger='blur'
                            />
                        </Col>

                        <Col span={8} offset={3}>
                            {/* 表单字段 */}
                            <Form.Input
                                field='gid'
                                label="屏蔽集群 GID"
                                trigger='blur'
                                rules={[
                                    { required: true, message: "该字段为必填项" },
                                ]}
                            />
                        </Col>

                        <Col span={8} offset={1}>
                            <Form.Input
                                field='service_code'
                                label="屏蔽服务代码"
                                placeholder="缺省为该集群全部服务"
                                trigger='blur'
                            />
                        </Col>

                        <Col span={17} offset={3}>
                            <Form.Input
                                field='ttl'
                                label="屏蔽时长 (秒)"
                                trigger='blur'
                                type="number"
                                initValue={86400}
                                rules={[
                                    { required: true, message: "该字段为必填项" },
                                ]}
                            />
                        </Col>

                        <Col span={17} offset={3}>
                            <Form.TextArea
                                field='desc'
                                label="备注"
                                trigger='blur'
                            />
                        </Col>

                        <Col span={18} offset={3}>
                            <Space>
                                {/* 确认弹出框，用于二次确认 */}
                                <Popconfirm
                                    title="确认"
                                    content={<div>确认进行告警屏蔽</div>}
                                    onConfirm={()=>handleConfirm()}
                                >
                                    <Button theme="solid">确认</Button>
                                </Popconfirm>
                                <Button theme="solid" onClick={()=>navigate(-1)}>返回</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>

            </PagePartialCard>
        </PageContainer>
    );
}

export default ToolAlarmMasking;
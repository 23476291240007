import {
  del,
  get,
  handleResponseEnvelope,
  patch,
  post,
  put,
} from "../utils/request";

export const createEntrypoint = async (payload) => {
  const { data } = await post(`/proxy/@sys/api/v1/entrypoints`, payload);
  return handleResponseEnvelope(data);
};

export const listEntrypoints = async (listOptions = {}) => {
  const { data } = await get(`/proxy/@sys/api/v1/entrypoints`, listOptions);
  return handleResponseEnvelope(data);
};

export const getEntrypointByUid = async (uid) => {
  const { data } = await get(
    `/proxy/@sys/api/v1/entrypoints/${uid}`
  );
  return handleResponseEnvelope(data);
};

export const patchEntrypointByUid = async ({ uid, payload }) => {
  const { data } = await patch(`/proxy/@sys/api/v1/entrypoints/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const saveEntrypointByUid = async ({ uid, payload }) => {
  const { data } = await put(`/proxy/@sys/api/v1/entrypoints/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const deleteEntrypointByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const { data } = await del(
    `/proxy/@sys/api/v1/entrypoints/${uid}?force=${force}`
  );
  return handleResponseEnvelope(data);
};

import React, {useRef} from 'react';
import ConfirmDialog from "../../../../components/Dialog/Confirm";
import {Box, Grid, LinearProgress, MenuItem} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {Select, TextField} from "formik-mui";
import {useNotification} from "../../../../hooks/useNotification";
import {useNavigate} from "react-router-dom";
import {useListEntrypoints, useSaveEntrypointByUid} from "../../../../hooks/query/entrypoint";
import {entrypointSpecType} from "../../../../constants/entrypoint";

function EntrypointEditDialog({open, setOpen, entrypoint}) {

    const notification = useNotification()
    const navigate = useNavigate()
    const formRef = useRef(null)

    const { refetch: refetchEntrypoints } = useListEntrypoints();

    const {mutate: saveEntrypointByUid} = useSaveEntrypointByUid()

    return (
        <ConfirmDialog
            open={open}
            title="快捷导航编辑"
            onClose={() => setOpen(false)}
            onConfirm={() => formRef.current.submitForm()}>
            <Box sx={{width: 650}}>
                <Formik
                    innerRef={formRef}
                    initialValues={entrypoint}
                    onSubmit={(values, {setSubmitting}) => {
                        console.log("newEntrypoint:", values)

                        saveEntrypointByUid({
                            uid: entrypoint.metadata.uid, payload: values
                        }, {
                            onSuccess: (data) => {
                                setSubmitting(false);
                                notification.success("快捷导航更新成功", `UID: ${data.metadata.uid}`)
                                refetchEntrypoints()
                                setOpen(false)
                            },
                            onError: (err) => {
                                setSubmitting(false);
                            }
                        })
                    }}
                >
                    {({submitForm, isSubmitting}) => (
                        <Form>
                            <Grid container spacing={2} sx={{py: 2}}>

                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        style={{width: "650px"}}
                                        component={Select}
                                        name="spec.type"
                                        label="导航分类"
                                    >
                                        <MenuItem value={entrypointSpecType.InternalSystem}>内部系统</MenuItem>
                                        <MenuItem value={entrypointSpecType.ExternalSystem}>常用运维系统</MenuItem>
                                        <MenuItem value={entrypointSpecType.QuickFunctions}>快捷功能入口</MenuItem>
                                        <MenuItem value={entrypointSpecType.Documents}>文档入口</MenuItem>
                                    </Field>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label="名称"
                                        name="metadata.name"
                                        size="small"
                                        validate={(val) => {
                                            let error;
                                            if (!val) {
                                                error = `名称为必填项`;
                                            }
                                            return error;
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label="描述"
                                        name="metadata.description"
                                        size="small"
                                        validate={(val) => {
                                            let error;
                                            if (!val) {
                                                error = `为导航提供一句简要清晰的描述是必要的`;
                                            }
                                            return error;
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label="主站 URL"
                                        name="spec.site_urls.main"
                                        size="small"
                                        validate={(val) => {
                                            let error;
                                            if (!val) {
                                                error = `主站 URL为必填项`;
                                            }
                                            return error;
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label="文档 URL"
                                        name="spec.docs_site_url"
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label="Logo URL"
                                        name="spec.logo_src"
                                        size="small"
                                    />
                                </Grid>

                                {isSubmitting && <Grid item xs={12}><LinearProgress/></Grid>}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </ConfirmDialog>
    );
}

export default EntrypointEditDialog;
import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {NavRoutes} from "../routes";
import {useDispatch} from "react-redux";
import {updateRouteStack} from "../state/modules/site";

function useRouteStack() {

    const dispatch = useDispatch()
    const location = useLocation();

    useEffect(() => {
        dispatch(updateRouteStack(getRouteStackByLocationPath(location.pathname)))
    }, [location]);

    // 递归展开 routes
    const flattenRoutesDeep = (routes = []) => {
        let flattenList = []

        routes.forEach(r => {
            if (r.path) {
                flattenList = [...flattenList, {
                    path: r.path,
                    title: r.title,
                    description: r.description,
                }];
            }

            if (r.children && r.children.length > 0) {
                const childRoutes = r.children
                let childList = flattenRoutesDeep(childRoutes)
                flattenList = [...flattenList, ...childList]
            }
        })

        return flattenList
    }

    const getRouteStackByLocationPath = (locationPath = "") => {
        let routeStack = []

        let paths = locationPath.split("/").filter((p) => p !== "");

        // 展开所有路由
        let allRoutes = []
        allRoutes = flattenRoutesDeep(NavRoutes)

        let matchPath = "";
        for (let i = 0; i < paths.length; i++) {
            // 逐层匹配路由栈
            matchPath += "/" + paths[i];
            let matchRoute = allRoutes.find(r => r.path === matchPath);
            if (matchRoute) {
                routeStack = [...routeStack, {
                    path: matchRoute.path,
                    title: matchRoute.title,
                    description: matchRoute.description,
                }]
            }
        }

        return routeStack;
    };
}

export default useRouteStack;
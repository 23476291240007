import { Chip } from "@mui/material";
import {entrypointSpecType} from "../../../../../constants/entrypoint";

export const SpecTypeChip = (specType) => {
  switch (specType) {
    case entrypointSpecType.InternalSystem:
      return <Chip size="small" color="primary" label="内部系统" />;
    case entrypointSpecType.QuickFunctions:
      return <Chip size="small" color="success" label="快捷功能入口" />;
    case entrypointSpecType.ExternalSystem:
      return <Chip size="small" color="warning" label="常用运维系统" />;
    case entrypointSpecType.Documents:
      return <Chip size="small" color="info" label="文档入口" />;
    default:
      return <Chip size="small" color="default" label="未知类别" />;
  }
};

import React, {useContext, useEffect, useState} from 'react';
import './Home.css'
import {
    Box, Button, Chip, Divider,
    Grid, Stack,
} from "@mui/material";
import ServiceHoverCard from "../../components/Card/ServiceHoverCard";
import {useListEntrypoints} from "../../hooks/query/entrypoint";
import {entrypointSpecType} from "../../constants/entrypoint";
import lodash from "lodash";
import {useNotification} from "../../hooks/useNotification";
import {Alert} from "@mui/lab";
import Navbar from "./Navbar/Navbar";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getUserByName, saveUserByName} from "../../state/modules/user";
import LoadingSkeleton from "./components/LoadingSkeleton";



function Home(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userState = useSelector(state => state.user)
    const { data: entrypoints, isFetching: isFetchingEntrypoints, refetch: refetchEntrypoints } = useListEntrypoints();

    const notification = useNotification()
    const [entrypointCards, setEntrypointCards] = useState([])

    const handleClickStar = (entrypointUid, isCollected) => {
        let newUser = lodash.cloneDeep(userState.current);

        if (isCollected) {
            newUser.collection.entrypoints = newUser.collection.entrypoints.filter(ep => ep !== entrypointUid)
        }else {
            let collectionSet = new Set(newUser.collection.entrypoints)
            collectionSet.add(entrypointUid)
            newUser.collection.entrypoints = Array.from(collectionSet)
        }


        dispatch(saveUserByName({name: newUser.metadata.name, data: newUser})).then(action => {
            dispatch(getUserByName({name: newUser.metadata.name}))
        })

    }

    useEffect(()=>{
        // 封装收藏信息到 ep cards
        if (entrypoints.items.length > 0) {
            let epCards = entrypoints.items.map(ep => {
                let isCollected = false
                if (userState.current.collection.entrypoints.includes(ep.metadata.uid)) {
                    isCollected = true
                }
                return {
                    ...ep,
                    isCollected: isCollected,
                }
            });
            setEntrypointCards(epCards)
        }
    }, [entrypoints, userState.current])

    return (
        <div>
            <Navbar entrypoints={entrypoints} />

            {/* 导航列表 */}
            <div className="arlo_tm_section" id="entrypoints">
                <div className="arlo_tm_services_wrap">
                    <div className="container">

                        <Stack spacing={1} sx={{mt: 2}}>
                            <h3>快捷导航</h3>
                            <span style={{color: 'rgba(0,0,0,0.6)'}}>系统保障组提供的运维工程化系统及快捷功能入口</span>
                        </Stack>

                        <Box sx={{margin: '32px 0 16px 0', fontSize: '22px', fontWeight: 'bold', borderLeft: '4px solid #E60012', paddingLeft: '16px'}}>
                            个人收藏
                        </Box>
                        <Grid container spacing={2}>

                            {isFetchingEntrypoints ? <Grid item xs={12}><LoadingSkeleton /></Grid> : entrypointCards.filter(epCard => userState.current.collection.entrypoints.includes(epCard.metadata.uid)).map((epCard, index) => (<Grid key={index} item xs={12} md={6} lg={4}>
                                <ServiceHoverCard
                                    entrypointCard={epCard}
                                    entrypointUid={epCard.metadata?.uid}
                                    name={epCard.metadata?.name}
                                    description={epCard.metadata?.description}
                                    isCollected={epCard.isCollected}
                                    onClickStar={handleClickStar}
                                />
                            </Grid>))}


                            {!isFetchingEntrypoints && entrypointCards.filter(epCard => userState.current.collection.entrypoints.includes(epCard.metadata.uid)).length === 0 && <Grid item xs={12}>
                                <Alert severity="info">暂无收藏的快捷导航</Alert>
                            </Grid>}

                        </Grid>


                        <Divider sx={{py: 3}} />


                        {/* 内部系统导航 */}
                        <Box sx={{margin: '32px 0 16px 0', fontSize: '22px', fontWeight: 'bold', borderLeft: '4px solid #E60012', paddingLeft: '16px'}}>
                            内部系统导航
                        </Box>
                        <Grid container spacing={2}>
                            {isFetchingEntrypoints ? <Grid item xs={12}><LoadingSkeleton /></Grid> : entrypointCards.filter(epCard => epCard.spec?.type === entrypointSpecType.InternalSystem).map((epCard, index) => (<Grid key={index} item xs={12} md={6} lg={4}>
                                <ServiceHoverCard
                                    entrypointCard={epCard}
                                    entrypointUid={epCard.metadata?.uid}
                                    name={epCard.metadata?.name}
                                    description={epCard.metadata?.description}
                                    isCollected={epCard.isCollected}
                                    onClickStar={handleClickStar}
                                />
                            </Grid>))}

                            {!isFetchingEntrypoints && entrypointCards.filter(epCard => epCard.spec?.type === entrypointSpecType.InternalSystem).length === 0 && <Grid item xs={12}>
                                <Alert severity="info">暂无可用的内部系统导航入口，待系统管理员添加</Alert>
                            </Grid>}
                        </Grid>

                        <Divider sx={{py: 3}} />

                        {/* 快捷功能入口 */}
                        <Box sx={{margin: '32px 0 16px 0', fontSize: '22px', fontWeight: 'bold', borderLeft: '4px solid #E60012', paddingLeft: '16px'}}>
                            快捷功能入口
                        </Box>
                        <Grid container spacing={2}>
                            {isFetchingEntrypoints ? <Grid item xs={12}><LoadingSkeleton /></Grid> : entrypointCards.filter(epCard => epCard.spec?.type === entrypointSpecType.QuickFunctions).map((epCard, index) => (<Grid key={index} item xs={12} md={6} lg={4}>
                                <ServiceHoverCard
                                    entrypointCard={epCard}
                                    entrypointUid={epCard.metadata?.uid}
                                    name={epCard.metadata?.name}
                                    description={epCard.metadata?.description}
                                    isCollected={epCard.isCollected}
                                    onClickStar={handleClickStar}
                                />
                            </Grid>))}

                            {!isFetchingEntrypoints && entrypointCards.filter(epCard => epCard.spec?.type === entrypointSpecType.QuickFunctions).length === 0 && <Grid item xs={12}>
                                <Alert severity="info">暂无可用的快捷功能入口，待系统管理员添加</Alert>
                            </Grid>}
                        </Grid>

                        <Divider sx={{py: 3}} />

                        {/* 常用运维系统 */}
                        <Box sx={{margin: '32px 0 16px 0', fontSize: '22px', fontWeight: 'bold', borderLeft: '4px solid #E60012', paddingLeft: '16px'}}>
                            常用运维系统
                        </Box>
                        <Grid container spacing={2}>
                            {isFetchingEntrypoints ? <Grid item xs={12}><LoadingSkeleton /></Grid> : entrypointCards.filter(epCard => epCard.spec?.type === entrypointSpecType.ExternalSystem).map((epCard, index) => (<Grid key={index} item xs={12} md={6} lg={4}>
                                <ServiceHoverCard
                                    entrypointCard={epCard}
                                    entrypointUid={epCard.metadata?.uid}
                                    name={epCard.metadata?.name}
                                    description={epCard.metadata?.description}
                                    isCollected={epCard.isCollected}
                                    onClickStar={handleClickStar}
                                />
                            </Grid>))}

                            {!isFetchingEntrypoints && entrypointCards.filter(epCard => epCard.spec?.type === entrypointSpecType.ExternalSystem).length === 0 && <Grid item xs={12}>
                                <Alert severity="info">暂无可用的常用运维系统入口，待系统管理员添加</Alert>
                            </Grid>}
                        </Grid>

                        <Divider sx={{py: 3}} />

                        {/* 常用文档导航 */}
                        <Box id="entrypoints-documents" sx={{margin: '32px 0 16px 0', fontSize: '22px', fontWeight: 'bold', borderLeft: '4px solid #E60012', paddingLeft: '16px'}}>
                            常用文档导航
                        </Box>
                        <Grid container spacing={2}>
                            {isFetchingEntrypoints ? <Grid item xs={12}><LoadingSkeleton /></Grid> : entrypointCards.filter(epCard => epCard.spec?.type === entrypointSpecType.Documents).map((epCard, index) => (<Grid key={index} item xs={12} md={6} lg={4}>
                                <ServiceHoverCard
                                    entrypointCard={epCard}
                                    entrypointUid={epCard.metadata?.uid}
                                    name={epCard.metadata?.name}
                                    description={epCard.metadata?.description}
                                    isCollected={epCard.isCollected}
                                    onClickStar={handleClickStar}
                                />
                            </Grid>))}

                            {!isFetchingEntrypoints && entrypointCards.filter(epCard => epCard.spec?.type === entrypointSpecType.Documents).length === 0 && <Grid item xs={12}>
                                <Alert severity="info">暂无可用的文档入口，待系统管理员添加</Alert>
                            </Grid>}
                        </Grid>
                    </div>
                </div>
            </div>

            <div className="arlo_tm_section" id="about">
                <div className="arlo_tm_hero_header_wrap">
                    <div className="arlo_tm_universal_box_wrap">
                        <div className="bg_wrap">
                            <div className="overlay_image hero jarallax" data-speed="0.1"></div>
                            <div className="overlay_color hero"></div>
                        </div>
                        <div className="content hero">
                            <div className="inner_content">
                                <div className="image_wrap">
                                    <img src="img/hero/img.png" alt=""/>
                                </div>
                                <div className="name_holder">
                                    <h3>基础架构 <span>系统保障组</span></h3>
                                </div>
                                <div style={{marginTop: '32px',color: '#fff'}}>
                                    保障基础架构稳定性，提供面向基础架构团队的运维工程化系统及服务
                                </div>
                                {/*<div className="text_typing">*/}
                                {/*    <p>我们 <span className="arlo_tm_animation_text_word"></span></p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="arlo_tm_arrow_wrap bounce anchor">
                            <a href="src/pages/Home/Home.js"><i className="xcon-angle-double-down"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;